import { useState } from "react";
import imgLogo from '../../img/img-logo.png'
import imgLogo2 from '../../img/img-logo2.png'
import imgLogo3 from '../../img/img-logo3.png'
import { Link } from "react-router-dom";

export default function Navbar(props) {
    return (
        <nav className="navbar navbar-expand-md bg-body-tertiary sticky-top">
            <div className="container-fluid">
                <div className="d-flex gap-0 align-items-center">
                    <Link className="navbar-brand fs-5" to="/">
                        <img src={imgLogo3} id="img-logo" className="d-inline-block" alt="Logo" />
                    </Link>
                    <Link className={`${props.classNameTitulo} navbar-brand `} id="navbar-titulo" to="/">
                        Falar Salva
                    </Link>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className={`${props.classNameItem} nav-link active fw-semibold navbar-item-custom`} aria-current="page" to="/">Início</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className={`${props.classNameItem} nav-link fw-semibold dropdown-toggle navbar-item-custom`} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Quiz
                            </a>
                            <ul className="dropdown-menu ">
                                <li><Link className={`dropdown-item navbar-item-custom`} to='/Quiz/Ansiedade'>Ansiedade</Link></li>
                                <li><Link className={`dropdown-item navbar-item-custom`} to='/Quiz/Depressão'>Depressão</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className={`${props.classNameItem} nav-link fw-semibold navbar-item-custom`} to="/psicologos">Psicólogos</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${props.classNameItem} nav-link fw-semibold navbar-item-custom`} to="/nutricionistas">Nutricionistas</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`${props.classNameItem} nav-link fw-semibold navbar-item-custom`} target="_blank" to="https://www.instagram.com/falarsalva.projeto/">Instagram</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}