import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import profissionais from "../../data/ProfissionaisData";
import { Link } from "react-router-dom";
import MobileDetect from "mobile-detect";

const responsive = {
    desktopLarger: {
        breakpoint: { max: 3000, min: 1400 },
        items: 5,
        slidesToSlide: 2
    },
    desktop: {
        breakpoint: { max: 1399, min: 1024 },
        items: 4,
        slidesToSlide: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 1
    },
    mobileLarger: {
        breakpoint: { max: 767, min: 576 },
        items: 2,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 575, min: 0 },
        items: 1,
        slidesToSlide: 1,
    }
};

export default function CustomCarousel() {
    const userAgent = navigator.userAgent;
    
    const md = new MobileDetect(userAgent);
    let deviceType = null;
    if (md.tablet()) {
      deviceType = "tablet";
    } else if (md.mobile()) {
      deviceType = "mobile";
    } else {
      deviceType = "desktop";
    }
    return (
        <Carousel
            autoPlay={deviceType === "mobile" || deviceType === "tablet" ? true : false}
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true} 
            infinite={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            containerClass=""
            removeArrowOnDeviceType={["mobile", "mobileLarger"]}
            // dotListClass="custom-dot-list-style"
            itemClass="px-2 px-sm-1"
            className="col-9 col-xl-10 mt-5 mt-xl-0 mx-auto"
            rewind={true}
        >
            {profissionais.map((x, index) => (
                <div key={index} className="col-12 position-relative div-carousel">
                    <Link to={`/psi/${x.Url}`} className="text-center link-offset-2 link-underline link-underline-opacity-0 ">
                    <img className="img-fluid img-carousel" src={x.Imagem} alt={x.Nome}></img>
                        <div className="position-absolute w-100 text-black px-1 texto-carousel">
                            <p className="my-0 fw-medium">
                                {x.Nome}
                            </p>
                            <p className="my-0">
                                {x.Titulo}
                            </p>
                        </div>
                    </Link>
                </div>
            ))}
        </Carousel>
    )
}