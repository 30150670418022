import profissionais from '../data/ProfissionaisData'


export function criaLinkWhatsapp(celular) {
    return `https://wa.me//${celular}?text=Ol%C3%A1%2C%20estou%20vindo%20pelo%20site%20do%20projeto%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es...`
}


export function ordenarLista(a, b) {
    let ordenacao = profissionais.map(profissional => profissional.Id);
    var mesAtual = new Date().getMonth() + 1;

    switch (mesAtual) {
        case 1:
        case 5:
        case 9:
            ordenacao.sort((a, b) => a - b);
            break;
        case 2:
        case 6:
        case 10:
            ordenacao = ordenacao.slice(6).concat(ordenacao.slice(0, 6));
            break;
        case 3:
        case 7:
        case 11:
            ordenacao = ordenacao.slice(2).concat(ordenacao.slice(0, 2));
            break;
        case 4:
        case 8:
        case 12:
            ordenacao = ordenacao.slice(8).concat(ordenacao.slice(0, 8));
            break;
    }
    return ordenacao.indexOf(a.Id) - ordenacao.indexOf(b.Id);
}


// export function ordenarLista(a, b) {
//     let ordenacao = profissionais.map(profissional => profissional.Id);
//     var mesAtual = new Date().getMonth() + 1;
//     const tamanho = ordenacao.length;

//     switch (mesAtual) {
//         case 1:
//         case 5:
//         case 9:
//             ordenacao.sort((a, b) => a - b);
//             break;
//         case 2:
//         case 6:
//         case 10:
//             if (tamanho >= 6) {
//                 ordenacao = ordenacao.slice(6).concat(ordenacao.slice(0, 6));
//             }
//             break;
//         case 3:
//         case 7:
//         case 11:
//             if (tamanho >= 2) {
//                 ordenacao = ordenacao.slice(2).concat(ordenacao.slice(0, 2));
//             }
//             break;
//         case 4:
//         case 8:
//         case 12:
//             if (tamanho >= 8) {
//                 ordenacao = ordenacao.slice(8).concat(ordenacao.slice(0, 8));
//             }
//             break;
//     }
//     return ordenacao.indexOf(a.Id) - ordenacao.indexOf(b.Id);
// }

