import { useParams, useNavigate, Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";

import profissionais from "../../data/NutricionistasData";
import Especialidade from "../../components/Especialidade/Especialidade";
import { useEffect } from "react";
import { criaLinkWhatsapp } from "../../services/services";
import Footer from "../../components/Footer/Footer";
import ScrollContainer from "react-indiana-drag-scroll";
import avaliacoesData from "../../data/AvaliacoesData";
import BalaoAvaliacao from "../../components/BalaoAvaliacao/BalaoAvaliacao";
import { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { FaArrowCircleLeft } from "react-icons/fa";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";

export default function Nutricionista() {
  const { url } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [avaliacoesProfissional, setAvaliacoesProfissional] = useState();
  const profissional = profissionais.find(
    (item) => item.Url === url
  );

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!profissional) {
      navigate("/");
    }

    setAvaliacoesProfissional(avaliacoes.filter(x => x.profissionalId == Id))

  }, [navigate, profissional]);

  const {
    Id,
    Url,
    Nome,
    Imagem,
    Celular,
    Titulo,
    CRN,
    Abordagem,
    Texto,
    Especialidades,
  } = profissional || {};
  const linkWhatsapp = criaLinkWhatsapp(Celular);
  const [avaliacoes, setAvaliacoes] = useState(avaliacoesData);

  const handleImageLoad = () => {
    setLoaded(true);
  };


  return (
    <>
      <div className="min-vh-100 d-flex flex-column">
        <Navbar classNameTitulo="text-danger-emphasis" classNameItem="text-danger-emphasis" />
        <div className="d-flex flex-column justify-content-center flex-fill mt-4 mt-sm-2 mb-5">
          {/* <div className="d-flex align-items-center mb-3 mb-sm-0" role="button" onClick={() => navigate(-1)}>
            <MdKeyboardDoubleArrowLeft className="text-danger-emphasis p-0 mx-2 btn border-0"  size='40px'  />
            <div className="text-danger-emphasis">VOLTAR</div>
          </div> */}
          <div className="d-flex mb-3 my-sm-2">
            <Link to="/nutricionistas" className="d-flex border-0 m-0 p-0 align-items-center btn btn-lg fw-medium link-offset-2 link-underline link-underline-opacity-0">
              <MdKeyboardDoubleArrowLeft className="flex-wrap text-danger-emphasis p-0 mx-2 btn border-0" size='40px' />
              <div className="flex-wrap text-danger-emphasis">VOLTAR</div>
            </Link>
          </div>
          <div className="background-profissional py-4 w-100">
            <div className="col-12 col-sm-11 mx-auto">
              <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center align-items-lg-stretch text-secondary-emphasis">

                {/* <FaArrowCircleLeft className="text-danger-emphasis p-0 me-4 btn border-0" role="button" size='40px' onClick={() => navigate(-1)} /> */}
                <div className="col-11 col-sm-10 col-md-8 col-lg-4 col-xxl-3 d-flex flex-column">
                  {!loaded &&
                    <div className="text-center">
                      <AiOutlineLoading color='black' className='load-spinner display-1' />
                    </div>
                  }
                  <img className={`img-fluid rounded ${!loaded ? 'd-none' : ''}`}
                    src={Imagem}
                    onLoad={handleImageLoad} />
                  <div className="text-center fs-5 fw-medium pt-2">{Titulo}</div>
                </div>
                <div className="mx-2 ms-lg-4 col-11 col-sm-12 col-md-12 col-lg-7 col-xl-8 col-xxl-8">
                  <p className="fs-4 fw-bold my-0 ">{Nome}</p>
                  <p className="fs-4 fw-medium my-0">CRN {CRN}</p>
                  <p className="fs-4 fw-light my-0">{Abordagem.join(', ')}</p>
                  <div className="d-flex my-2 ms-auto flex-wrap">
                    {Especialidades &&
                      Especialidades.map((especialidade, index) => (
                        <Especialidade
                          especialidade={especialidade}
                          key={index}
                        />
                      ))}
                  </div>
                  <p className="fs-5 fw-normal my-0" dangerouslySetInnerHTML={{ __html: Texto }}></p>
                  <div className="d-flex">
                    {/* <Link
                      className="d-none d-sm-block btn btn-lg ms-auto fw-bold rounded-1 mt-5 btn-bege"
                      to={linkWhatsapp}
                      target="_blank"
                    >Agendar sessão</Link> */}
                    <Link className="btn btn-lg ms-auto mt-5 fw-bold rounded-1 btn-outline-success" to={linkWhatsapp} target="_blank">Agendar consulta <FaWhatsapp className="fs-3 mb-1" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {avaliacoesProfissional && avaliacoesProfissional.length > 0 &&
          <div className="background-avaliacoes my-4 pt-4 pb-1">
            <ScrollContainer activationDistance="50" hideScrollbars="false">
              <div className="d-flex">
                {avaliacoesProfissional && avaliacoesProfissional.map((avaliacao, index) => (
                  <BalaoAvaliacao key={index} avaliacao={avaliacao} />
                ))}
              </div>
            </ScrollContainer>
          </div>
        }
        <Footer />
      </div >
    </>
  );
}
