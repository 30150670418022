import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Index from './pages/Index/Index'
import Psicologo from './pages/Profissional/Psicologo';
import Psicologos from './pages/Profissionais/Psicologos';
import Nutricionistas from './pages/Profissionais/Nutricionistas';
import Quiz from './pages/Quiz/Quiz';
import AtendimentoOnline from './pages/AtendimentoOnline/AtendimentoOnline';
import Nutricionista from './pages/Profissional/Nutricionista';

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/" element={<Index />} />            
            <Route path="/atendimento-online" element={<AtendimentoOnline />} />            
            <Route path="/psicologos" element={<Psicologos />} />            
            <Route path="/nutricionistas" element={<Nutricionistas />} />            
            <Route path="/quiz/:condicao" element={<Quiz />} />            
            {/* <Route path="/Psicologos?especialidade=:especialidade" element={<Psicologos />} /> */}
            {/* <Route path="/Psicologos/:especialidade/:abordagem" element={<Psicologos />} /> */}
            <Route path="/psi/:url" element={<Psicologo />} />
            <Route path="/nutri/:url" element={<Nutricionista />} />
        </Routes>
    )
}