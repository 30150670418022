import { React, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'
import NutricionistaMiniatura from '../../components/Miniatura/NutricionistaMiniatura'
import Disclaimer from '../../components/Disclaimer/Disclaimer'
import Footer from '../../components/Footer/Footer'
import { AiOutlineLoading, AiOutlineLoading3Quarters } from 'react-icons/ai'
import imgBg from '../../img/img2.jpg'

import profissionais from '../../data/NutricionistasData'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md'
import { ordenarLista } from '../../services/services'

export default function Nutricionistas() {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <div className="min-vh-100 d-flex flex-column">
                <Navbar classNameTitulo="text-danger-emphasis" classNameItem="text-danger-emphasis" />
                <div className="d-flex my-4">
                    <Link to="/" className="d-flex border-0 m-0 p-0 pe-3 align-items-center fw-medium link-offset-2 link-underline link-underline-opacity-0 rounded-end-2 bg-branco-transparente btn-voltar">
                        <MdKeyboardDoubleArrowLeft className="flex-wrap p-0 mx-2 btn border-0" size='40px' />
                        <div className="flex-wrap text-dark fw-medium">VOLTAR</div>
                    </Link>
                </div>
                {profissionais.length > 0 &&
                    <FadeIn transitionDuration='800'>
                        <div className='d-flex flex-wrap'>
                            {profissionais &&
                                profissionais.length &&
                                profissionais.map((profissional, index) => (
                                    <NutricionistaMiniatura key={index} profissional={profissional} />
                                ))}
                        </div>
                    </FadeIn>
                }
            
                {
                    <Disclaimer className="mb-5" />
                }
                <Footer />
            </div >
        </>
    )
}