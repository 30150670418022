import imgJoanaGuerra from '../img/nutri/joana-guerra.jpg'

const nutricionistas = [
    {
        Id: 1,
        Nome: "Joana Guerra",
        Url: "joana-guerra",
        Imagem: imgJoanaGuerra,
        Titulo: "Nutricionista Funcional",
        CRN: "21327/P",
        Abordagem: ["Nutricionista Clínica Funcional", "Pós graduada em Fitoterapia"],
        Texto: "Meu acompanhamento nutricional é 100% personalizado baseado nas necessidades, na rotina e preferências do paciente. Não trato apenas sintomas e queixas, busco entender a raiz dos desequilíbrios, levando em consideração o paciente como único e sempre com objetivo de otimizar a saúde em todos os níveis.",
        Celular: "559886257997",
        Especialidades: ["Exames laboratoriais", "Doenças autoimunes", "Doenças crônicas não transmissíveis", "Saúde da mulher", "Obesidade", "Emagrecimento e hipertrofia"]
    },
]

export default nutricionistas